import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../../App.css';
import logo from '../../../img_logo.png'
import iconApp from '../../../Icon-App-1024.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import queryString from 'query-string';
import Error404 from '../../../component/common/error/Error404';
import Error500 from "../../../component/common/error/Error500";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {addBreadcrumb, captureException} from "../../../utils/sentry";

function Input() {
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);

  const [errorMsg, setErrorMsg] = useState();
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState();

  const [token, setToken] = useState();
  const [password, setPassword] = useState();
  const handleChangePassword = (event) => {
    setPassword(event.target.value)
  };
  const [isShowPassword, setIsShowPassword] = useState(false)
  const handleChangeShowPassword = (event) => {
    setIsShowPassword(!isShowPassword)
  };
  const [validPassword, setValidPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();
  const handleChangeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value)
  };
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
  const handleChangeShowConfirmPassword = (event) => {
    setIsShowConfirmPassword(!isShowConfirmPassword)
  };
  const [validConfirmPassword, setValidConfirmPassword] = useState("");

  useEffect(() => {

    if (!parsed || Object.keys(parsed).length <= 0 || !parsed["token"] || parsed["token"] === "") {
      setIsError(true);
      setErrorType(404);
      return
    }
    verifyToken(parsed["token"])
  }, [isError]);

  async function verifyToken(token) {
    addBreadcrumb(
      'axios.post',
      "password-reset call", {
        Field: "verify",
        Arguments: {
          token: token
        }
      });
    await axios.post("https://dev.app-user.totono.app/password/reset", {
      Field: "verify",
      Arguments: {
        token: token
      }
    })
      .then((results) => {
        if (results.data.statusCode === 404) {
          setIsError(true);
          setErrorType(404);
        }
        if (results.data.statusCode === 500) {
          setIsError(true);
          setErrorType(500);
        }
        if (results.data.statusCode === 601) {
          setIsError(true);
          setErrorType(404);
        }
        setToken(token)
      }).catch(() => {
        captureException();
        console.log('通信に失敗しました。');
      });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      return;
    }
    addBreadcrumb(
      'axios.post',
      "password-reset call", {
        Field: "reset",
        Arguments: {
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        }
      });
    await axios.post("https://dev.app-user.totono.app/password/reset", {
      Field: "reset",
      Arguments: {
        token: token,
        password: password,
        confirmPassword: confirmPassword,
      }
    })
      .then((results) => {
        if (results.data.statusCode === 200) {
          history.push("/password/reset/complete")
        }
        if (results.data.statusCode === 404) {
          setErrorMsg("エラーが発生しました。")
        }
        if (results.data.statusCode === 500) {
          setErrorMsg("エラーが発生しました。\n時間を置いてもう一度お試しください。")
        }
        if (results.data.statusCode === 601) {
          setErrorMsg("エラーが発生しました。\nお手数ですが再度パスワード再発行をお願い致します。")
        }
      }).catch(() => {
        captureException();
        console.log('通信に失敗しました。');
      });
  };

  function validateForm() {
    let isError = false;
    setValidPassword("");
    setValidConfirmPassword("");
    if (!password) {
      isError = true;
      setValidPassword("必須入力です")
    } else {
      if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.)([a-zA-Z\d]|.){8,50}$/)) {
        isError = true;
        setValidPassword("8文字以上で、英大文字、英小文字、数字を含むようにしてください")
      }
      if (!confirmPassword) {
        isError = true;
        setValidConfirmPassword("必須入力です")
      } else {
        if (password !== confirmPassword) {
          isError = true;
          setValidConfirmPassword("パスワードとパスワード(確認用)の入力が一致しません")
        } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*.)([a-zA-Z\d]|.){8,50}$/)) {
          isError = true;
          setValidConfirmPassword("8文字以上で、英大文字、英小文字、数字を含むようにしてください")
        }
      }
    }
    return isError
  }

  function GetError() {
    switch (errorType) {
      case 404: {
        return (
          <Error404/>
        )
      }
      case 500: {
        return (
          <Error500/>
        )
      }
      default: {
        return (
          <Error404/>
        )
      }
    }
  }

  return (
    <>
      {isError ? (
        <GetError/>
      ) : (
        <div className="App">
          <header className="App-header">
            <Grid
              container
              style={{backgroundColor: '#ffffff', paddingTop: 20, paddingBottom: 20}}
            >
              <Grid
                item
                xs={12}
              >
                <img src={logo} alt="logo"/>
              </Grid>
            </Grid>
            <Grid
              container
              style={{backgroundColor: '#ffffff', marginTop: 9, paddingTop: 38, paddingBottom: 38}}
            >
              <Grid
                item
                xs={12}
              >
                <Typography component="h2" variant="h6">
                  新しいパスワードを設定
                </Typography>
                <img src={iconApp} alt="iconApp" width="96" height="96"
                     style={{marginTop: 24, marginBottom: 24, borderRadius: 24}}/>
                <Typography variant="body1" component="h2">
                  8文字以上で、英大文字、英小文字、数字それぞれを、<br/>
                  最低1文字ずつ含むようにしてください。
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                {errorMsg != null && errorMsg && (
                  <Typography variant="body1" component="h2" color={"secondary"} style={{whiteSpace: "pre-line"}}>
                    {errorMsg}
                  </Typography>
                )}
              </Grid>
              <form
                onSubmit={handleSubmit}
                style={{width: '640px', margin: 'auto'}}
              >
                <Grid
                  item
                  xs={12}
                  style={{marginTop: 40}}
                >
                  <TextField
                    required
                    label="パスワード"
                    type={isShowPassword ? "text ": "password"}
                    value={password}
                    autoComplete="current-password"
                    onChange={handleChangePassword}
                    style={{width: '84%'}}
                    error={validPassword !== "" ? true : false}
                    helperText={validPassword}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleChangeShowPassword}
                        >
                          {isShowPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{marginTop: 40}}
                >
                  <TextField
                    required
                    label="パスワード(確認用)"
                    type={isShowConfirmPassword ? "text ": "password"}
                    value={confirmPassword}
                    autoComplete="current-password"
                    onChange={handleChangeConfirmPassword}
                    style={{width: '84%'}}
                    error={validConfirmPassword !== "" ? true : false}
                    helperText={validConfirmPassword}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleChangeShowConfirmPassword}
                        >
                          {isShowConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{marginTop: 40}}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      width: '72%',
                      height: '48px',
                      color: '#ffffff',
                      backgroundColor: '#6eb9a5',
                      fontSize: '1.3rem'
                    }}
                  >
                    送信
                  </Button>
                </Grid>
              </form>
            </Grid>
          </header>
        </div>
      )}
    </>
  );
}

export default Input;
