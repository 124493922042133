import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import ActivateSuccess from './containers/activate/Success';
import ActivateFailure from './containers/activate/Failure';
import ChangeSuccess from './containers/change/Success';
import ChangeFailure from './containers/change/Failure';
import PasswordResetAccept from './containers/password/reset/accept/Input';
import PasswordResetAcceptComplete from "./containers/password/reset/accept/Complete";
import PasswordResetInput from './containers/password/reset/Input';
import PasswordResetComplete from "./containers/password/reset/Complete";
import Error404 from "./component/common/error/Error404";
import UsePageTracking from "./component/common/UsePageTracking";

function App() {
  return (
    <Router>
      <UsePageTracking/>
      <div>
        <Switch>
          <Route path="/activate/success">
            <ActivateSuccess />
          </Route>
          <Route path="/activate/failure">
            <ActivateFailure />
          </Route>
          <Route path="/change/success">
            <ChangeSuccess />
          </Route>
          <Route path="/change/failure">
            <ChangeFailure />
          </Route>
          <Route exact path="/password/reset/accept">
            <PasswordResetAccept />
          </Route>
          <Route exact path="/password/reset/accept/success">
            <PasswordResetAcceptComplete />
          </Route>
          <Route exact path="/password/reset/input">
            <PasswordResetInput />
          </Route>
          <Route exact path="/password/reset/complete">
            <PasswordResetComplete />
          </Route>
          <Route>
            <Error404 />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Sentry.withProfiler(App);
