import React from 'react';
import '../../../App.css';
import logo from '../../../img_logo.png'
import iconApp from '../../../Icon-App-1024.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';



function Error500() {
  return (
    <div className="App">
      <header className="App-header">
        <Grid
          container
          style={{backgroundColor: '#ffffff', paddingTop: 20, paddingBottom: 20}}
        >
          <Grid
            item
            xs={12}
          >
            <img src={logo} alt="logo"/>
          </Grid>
        </Grid>
        <Grid
          container
          style={{backgroundColor: '#ffffff', marginTop: 9, paddingTop: 38, paddingBottom: 38}}
        >
          <Grid
            item
            xs={12}
          >
            <Typography component="h2" variant="h6">
              エラー
            </Typography>
            <img src={iconApp} alt="iconApp" width="96" height="96"
                 style={{marginTop: 24, marginBottom: 24, borderRadius: 24}}/>
            <Typography variant="body1" component="h2">
              技術的なエラーが発生しました。
            </Typography>
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default Error500;
