import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";

export default function UsePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize('UA-175550156-1', {
        debug: true   // react-gaのログ出力ON
      });
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);

  return null;
}

